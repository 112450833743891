import React from "react";

import SendIcon from "@mui/icons-material/Send";
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { FormContext } from "../../../../context/FormContext";
import { calculatePrice } from "../../../../core/utils/price";

import { S } from "./styles";
import { Mui } from "./stylesMui";

import DATA from "../../../../assets/data/productsList.json";

const SubmitPage = ({ setSubmited }) => {
  const { form } = React.useContext(FormContext);
  const price = calculatePrice(form, DATA);

  const [contactForm, setContactForm] = React.useState({
    name: "",
    phone: "",
    email: "",
    err: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    // valid
    const errList = [];
    if (contactForm["name"].length <= 0) {
      errList.push("name");
    }
    if (contactForm["phone"].length <= 0) {
      errList.push("phone");
    }
    if (
      contactForm["email"].length <= 0 ||
      !contactForm["email"].includes("@")
    ) {
      errList.push("email");
    }

    if (errList.length !== 0) {
      setContactForm((prev) => ({ ...prev, err: errList }));
      return;
    }

    window.emailjs.send("service_xns4l6l", "template_enj6f6d", {
      name: contactForm["name"],
      phone: contactForm["phone"],
      email: contactForm["email"],

      number: form.q1.value,
      q2Name: form.q2.zhValue,
      q3Name: form.q3.zhValue,

      q4: form.q4.zhValue,
      pattern: form.q5.p1,
      color: form.q5.p2,
      isWithoutImage: form.q5.isWithoutImage,
      q6: form.q6.zhValue,

      price: price,
    });

    setSubmited(true);
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.LeftContainer>
          <S.Title>您的報價單</S.Title>
          <S.TextContainer>
            <S.Text>數量：{form.q1.value}</S.Text>
            <S.Text>類型：{form.q2.zhValue}</S.Text>
            <S.Text>款式：{form.q3.zhValue}</S.Text>
            <S.Text>工法：{form.q4.zhValue}</S.Text>
            <S.Text>圖案：{form.q5.zhValue}</S.Text>
          </S.TextContainer>
          <S.HintText>預估金額：{price}</S.HintText>
        </S.LeftContainer>
        <S.RightContainer>
          <S.Title>聯絡資訊</S.Title>
          <S.Info>填寫您的聯絡資訊，我們會在24小時內與你聯絡！</S.Info>
          <S.Box>
            <S.Item>
              <Mui.ModalTextfield
                required
                type="text"
                name="name"
                value={contactForm.name}
                onChange={handleChange}
                label="姓名"
                error={contactForm.err.includes("name")}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <AccountCircle />
                    </Mui.InputAdornment>
                  ),
                }}
              />
            </S.Item>
            <S.Item>
              <Mui.ModalTextfield
                required
                name="phone"
                type="phone"
                value={contactForm.phone}
                onChange={handleChange}
                label="聯絡電話"
                error={contactForm.err.includes("phone")}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </Mui.InputAdornment>
                  ),
                }}
              ></Mui.ModalTextfield>
            </S.Item>
            <S.Item>
              <Mui.ModalTextfield
                required
                name="email"
                type="email"
                value={contactForm.email}
                onChange={handleChange}
                label="聯絡信箱"
                error={contactForm.err.includes("email")}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <EmailIcon />
                    </Mui.InputAdornment>
                  ),
                }}
              />
            </S.Item>
          </S.Box>
          <Mui.ModalSendButton
            onClick={onSubmit}
            variant="contained"
            endIcon={<SendIcon />}
            size="large"
          >
            確認送出
          </Mui.ModalSendButton>
        </S.RightContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default SubmitPage;
