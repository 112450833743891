import styled from "styled-components";

import { font, color } from "../../../../core/unit/styles";

export const S = {
  Box: styled.div`
    padding: 0px 0px 0px 32px;
    margin: 23px auto 0px;
    > * + * {
      margin-top: 11px;
    }
  `,
  Radio: styled.label`
    position: relative;
    padding-left: 28px;
    font: normal normal bold 16px/17px Helvetica Neue;
    letter-spacing: 0.11px;
    color: #717171;

    ::before {
      content: "";
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    ::after {
      content: "";
      width: 10px;
      height: 10px;
      background: #f87da9;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      visibility: ${(props) => (props.active ? "visible" : "hidden")};
    }
  `,
  RadioBox: styled.div`
    font-size: 14px;
    font-family: ${font.Forntfamily};
    letter-spacing: 0.13px;
    color: #717171;
  `,
  RadioText: styled.div`
    padding-left: 28px;
    font: normal normal normal 14px/19px Helvetica Neue;
    letter-spacing: 0.13px;
    color: #717171;
    padding-top: 5px;
  `,
  SubQuestionBox: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: left;
    margin-bottom: 5px;
  `,
  ListContainer: styled.div`
    height:80%;
    overflow-y: scroll;
  `,
  Overlay: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  GreyOverlay: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${color.Black};
    opacity: 0.2;
  `,
};
