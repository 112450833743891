import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { FormProvider } from "../context/FormContext";
import FormPage from "../views/Form";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <FormProvider>
          <Route exact path="/FormPage" component={FormPage} />
        </FormProvider>
      </Switch>
    </Router>
  );
}
