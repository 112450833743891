import React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { FormContext } from "../../../../context/FormContext";

import { S } from "./styles";
import { Mui } from "./stylesMUI";
import { S as GS } from "../styles";
import { Mui as GMui } from "../stylesMUI";

import { ImageModal } from "../../WrapperModal";

import DATA from "../../../../assets/data/productsList.json";

const Question3 = () => {
  const [data] = React.useState(DATA);
  const { form, setForm } = React.useContext(FormContext);
  const value = form.q3.value;
  const q2Value = form.q2.value;
  const q2NamneZh = data[q2Value]["name_zh"];
  const [model, setModel] = React.useState({
    visible: false,
    imgLink: "",
  });

  const onClick = (v) => {
    setForm((prev) => ({
      ...prev,
      q3: {
        status: true,
        value: v.value,
        zhValue: v.name,
      },
    }));
  };

  const onVisibleModel = (imgLink) => {
    setModel((props) => ({ ...props, visible: true,  imgLink: imgLink}));
  }

  const renderContainer = () => {
    return (
      <S.ListContainer>
        <ImageList
          gap={12}
          sx={{
            m: 2,
            gridTemplateColumns:
              "repeat(auto-fill,minmax(280px, 1fr)) !important",
          }}
        >
          {data[q2Value]["data"].map((v, i) => (
            <Mui.ImageListItem key={i}>
              <img
                name={v.name}
                src={`/images/${v.img}`}
                srcSet={`/images/${v.img}`}
                alt={v.value}
                loading="lazy"
                style={{
                  cursor: "pointer",
                  padding: "4px",
                  height: "100%",
                  width: "100%",
                }}
                onClick={() => onClick(v)}
              />

              <ImageListItemBar
                title={<GMui.Typography>{v.description}</GMui.Typography>}
                subtitle={
                  <GS.TypographyBox>
                    <GMui.TitleTypography>{v.name}</GMui.TitleTypography>
                    <GMui.Typography>{v.description_price} 元起</GMui.Typography>
                  </GS.TypographyBox>
                }
                style={{ zIndex: 2000, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.8)", zIndex: 2000 }}
                    aria-label={`info about ${v.name}`}
                    size="large"
                    title="商品資訊"
                    onClick={() => onVisibleModel(`/images/${v.img}`)}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
              {value === v.value ? (
                <S.Overlay>
                  <CheckCircleOutlineRoundedIcon
                    sx={{ fontSize: 70, color: "white", zIndex: "1000" }}
                  />
                  <S.GreyOverlay />
                </S.Overlay>
              ) : null}
            </Mui.ImageListItem>
          ))}
        </ImageList>
      </S.ListContainer>
    );
  };
  return (
    <GS.Wrapper>
      <GS.Box>
        <GS.Text>選擇喜歡的{q2NamneZh}款式</GS.Text>
        {renderContainer()}
      </GS.Box>
      <ImageModal visible={model.visible} setModel={setModel} imgLink={model.imgLink}></ImageModal>
    </GS.Wrapper>
  );
};

export default Question3;
