// 成本+ (500 / 數量 + 8 )* 圖案總數 ＋(500 / 數量 + 3 )* (顏色總數 - 圖案總數) + 加工包裝＋利潤 + (200 / 數量)
const thinPrice = (form, data) => {
  let benefit = 100;
  let packagingFee = 10;
  if (form.q1.value <= 19) {
    benefit += 100;
  } else if (form.q1.value <= 29) {
    benefit += 20;
  } else if (form.q1.value === 30) {
    benefit += 0;
  } else if (form.q1.value > 30 && form.q1.value <= 500) {
    benefit = benefit - 5 * parseInt(form.q1.value / 50);
  } else {
    //數量超過500件，優惠以500件計算
    benefit = benefit - 5 * (500 / 50);
  }

  let patternAmount = form.q5.p1;
  if(patternAmount === "" || patternAmount === 0){
    patternAmount = 0;
  }
  let colorAmount = form.q5.p2;
  if(colorAmount === "" || colorAmount === 0){
    colorAmount = 0;
  }

  const price =
    data["cost"] +
    (500 / form.q1.value + 10) * patternAmount +
    (500 / form.q1.value + 5) * (colorAmount - patternAmount) +
    packagingFee +
    benefit +
    200 / form.q1.value;
  return Math.ceil(price); //無條件進位
};

// 成本+ (500 / 數量 + 16 )* 圖案總數 ＋(500 / 數量 + 5 )* (顏色總數 - 圖案總數) + 加工包裝 ＋利潤+ (200 / 數量)
const heavyPrice = (form, data) => {
  let benefit = 150;
  let packagingFee = 15;
  if (form.q1.value <= 19) {
    benefit += 100;
  } else if (form.q1.value <= 29) {
    benefit += 20;
  } else if (form.q1.value === 30) {
    benefit += 0;
  } else if (form.q1.value > 30 && form.q1.value <= 500) {
    benefit = benefit - 8 * parseInt(form.q1.value / 50);
  } else {
    //數量超過500件，優惠以500件計算
    benefit = benefit - 8 * (500 / 50);
  }

  let patternAmount = form.q5.p1;
  if(patternAmount === "" || patternAmount === 0){
    patternAmount = 0;
  }
  let colorAmount = form.q5.p2;
  if(colorAmount === "" || colorAmount === 0){
    colorAmount = 0;
  }

  const price =
    data["cost"] +
    (500 / form.q1.value + 16) * patternAmount +
    (500 / form.q1.value + 5) * (colorAmount - patternAmount) +
    packagingFee +
    benefit +
    200 / form.q1.value;
  return Math.ceil(price); //無條件進位
};

// 成本+ (500 / 數量 + 40 )* 圖案總數 ＋(500 / 數量 + 10 )* (顏色總數 - 圖案總數) + 加工包裝＋利潤 + (300 / 數量)
const specialPrice = (form, data) => {
  let benefit = 150;
  let packagingFee = 15;
  if (form.q1.value <= 19) {
    benefit += 100;
  } else if (form.q1.value <= 29) {
    benefit += 20;
  } else if (form.q1.value === 30) {
    benefit += 0;
  } else if (form.q1.value > 30 && form.q1.value <= 500) {
    benefit = benefit - 8 * parseInt(form.q1.value / 50);
  } else {
    //數量超過500件，優惠以500件計算
    benefit = benefit - 8 * (500 / 50);
  }

  let patternAmount = form.q5.p1;
  if(patternAmount === "" || patternAmount === 0){
    patternAmount = 0;
  }
  let colorAmount = form.q5.p2;
  if(colorAmount === "" || colorAmount === 0){
    colorAmount = 0;
  }

  const price =
    data["cost"] +
    (500 / form.q1 + 40) * patternAmount +
    (500 / form.q1 + 10) * (colorAmount - patternAmount) +
    packagingFee +
    benefit +
    300 / form.q1;
  return Math.ceil(price); //無條件進位
};

export const calculatePrice = (form, rawData) => {
  let status =
    form.q1.status &&
    form.q2.status &&
    form.q3.status 
  if (status === false) {
    return 0;
  }
  const data = rawData[form.q2.value]["data"].find(
    (v) => v.value === form.q3.value
  );
  const type = data["type"];

  if (type === "thin") {
    return thinPrice(form, data);
  } else if (type === "heavy") {
    return heavyPrice(form, data);
  } else {
    return specialPrice(form, data);
  }
};
