import styled from "styled-components";
import { color, font, device } from "../../../../core/unit/styles";

export const S = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 22px;
    @media ${device.XS} {
      width: 100%;
    }
  `,
  Container: styled.div`
    width: 60%;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 26px 30px 55px;
    @media ${device.XS} {
      width: 80%;
      padding: 2px 30px 24px;
    }
  `,
  LeftContainer: styled.div`
    flex-wrap: nowrap;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    @media ${device.XS} {
      display: block;
      padding-bottom: 12px;
    }
  `,
  RightContainer: styled.div`
    margin-top: 20px;
  `,
  Title: styled.p`
    font-size: 20px;
    font-family: ${font.Forntfamily}
    letter-spacing: 2px;
    color: ${color.Text};
    text-align: center;
    margin-bottom: 24px;
    @media ${device.XS} {
      margin-top: 16px;
      margin-bottom: 8px;
    }
  `,
  TextContainer: styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    @media ${device.XS} {
      width: 90%;
      margin-bottom: 18px;
    }
  `,
  Text: styled.p`
    width:100%;
    font: ${font.Forntfamily};
    letter-spacing: 1px;
    text-align:left;
    color: ${color.Text};
    margin-top: 12px;
    @media ${device.XS} {
      margin-top: 8px;
    }
  `,
  HintText: styled.p`
    font: ${font.Forntfamily};
    letter-spacing: 2px;
    text-align:center;
    color: ${color.MainGreen};
    margin-top: 16px;
    @media ${device.XS} {
      margin-top: 8px;
    }
  `,
  Item: styled.div``,
  Box: styled.div`
    margin-top: 22px;
    > * + * {
      margin-top: 26px;
    }
  `,
  Info: styled.h3`
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-family: ${font.FontFamily};
    letter-spacing: 0.1px;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-top: 4px;
    color: ${color.Gray};
  `,
};
