import styled from "styled-components";

import { color } from "../../../../core/unit/styles";

export const S = {
  ProductImg: styled.img`
    width: 100%;
    height: 100%;
    background: ${(props) => (props.active ? "#FFD30D" : "white")} 0% 0%
      no-repeat padding-box;
    border: 1px solid ${(props) => (props.active ? "#ff770d" : "#CBCBCB")};
    border-radius: 4px;
    cursor: pointer;
    background-image: url(${(props) => (props.image ? props.image : null)});
    background-repeat: round;
    :hover {
      background: ${(props) =>
        props.image ? null : "#F1A53D 0% 0% no-repeat padding-box"};
      border: 1px solid ${color.MainGreen};
    }
  `,
  Overlay: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  GreyOverlay: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${color.Black};
    opacity: 0.2;
  `,
  ListContainer: styled.div`
    height:80%;
    overflow-y:scroll;
  `,

};
