import styled from "styled-components";
import { device, font, color } from "../../../../core/unit/styles";

export const S = {
  Box: styled.div`
    align-items: center;
    margin: 23px auto 12px;
    > * + * {
      margin-top: 16px;
    }
    @media ${device.XS} {
      margin:0px;
      padding : 8px;
      height:80%;
      overflow-y:scroll;
    }
  `,
  Block: styled.div``,
  Item: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: left;
  `,
  CheckBoxWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Text: styled.p`
    width:100%
    font-size: 16px/19px ;
    font-family: ${font.Forntfamily}
    font-weight: 400;
    letter-spacing: 0.2px;
    color: ${color.Text};
    margin: 0px 0px 12px 0px;
    text-align: center;
    @media ${device.XS} {
      margin: 0px 0px 8px 0px;
      text-align: left;
    }
  `,
};
