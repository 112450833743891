import { styled } from "@mui/system";
import { FormControl, Select, MenuItem,FormControlLabel, Checkbox } from "@mui/material";
import { color, font } from "../../../../core/unit/styles";

export const Mui = {
  StyledFormControl: styled(FormControl)`
    margin: 1px 5px;
    width: 50%;
    height: 100%;
    color: ${color.Black};
    font-family: ${font.Forntfamily};
    & label.Mui-focused {
      color: ${color.Green};
    }
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${color.Black};
      }
      &:hover fieldset {
        border-color: ${color.Gray};
      }
      &.Mui-focused fieldset {
        border-color: ${color.Green};
      }
    }
  `,
  StyledSelect: styled(Select)`
    text-align: center;
    font-size: 16px;
    font-family: ${font.Forntfamily};
  `,
  StyledMenuItem: styled(MenuItem)`
    font-family: ${font.Forntfamily};
  `,
  StyledFormControlLabel: styled(FormControlLabel)`
    color: ${color.Text};
    font-family: ${font.Forntfamily};
  `,
  StyledCheckbox: styled(Checkbox)`
    color: ${color.Text};
    &.Mui-checked {
      color: ${color.Green};
    },
  `,
};
