import IMG0 from "../../../../assets/images/q4Description/工法.000.webp";
import IMG1 from "../../../../assets/images/q4Description/工法.001.webp";
import IMG2 from "../../../../assets/images/q4Description/工法.002.webp";
import IMG3 from "../../../../assets/images/q4Description/工法.003.webp";
import IMG4 from "../../../../assets/images/q4Description/工法.004.webp";
import IMG6 from "../../../../assets/images/q4Description/工法.006.webp";

export const ITEMS = [
  {
    id: 0,
    name: "q4-0",
    value: "0",
    label: "尚未有想法",
    info: "我們將在後續和您討論設計",
    descriptionImg: IMG0,
  },
  {
    id: 1,
    name: "q4-1",
    value: "1",
    label: "數位直噴",
    info: "顏色無限、圖案精緻，但單價較貴（適合大圖）",
    descriptionImg: IMG1,
  },
  {
    id: 2,
    name: "q4-2",
    value: "2",
    label: "網版印刷",
    info: "如果顏色少，這是最經濟實惠的工法",
    descriptionImg: IMG2,
  },
  {
    id: 3,
    name: "q4-3",
    value: "3",
    label: "電腦刺繡",
    info: "精緻度大提升！但單價較貴",
    descriptionImg: IMG3,
  },
  {
    id: 4,
    name: "q4-4",
    value: "4",
    label: "膠膜熱轉印",
    info: "顏色無限、圖案精緻，但單價較貴（適合小圖）",
    descriptionImg: IMG4,
  },
  {
    id: 5,
    name: "q4-5",
    value: "5",
    label: "更多的想法",
    info: "我們將在後續和您討論設計",
    descriptionImg: IMG6,
  },
];
