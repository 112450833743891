import { styled } from "@mui/system";
import { Button, TextField, InputAdornment } from "@mui/material";
import { color, font, device } from "../../../../core/unit/styles";

export const Mui = {
  ModalTextfield: styled(TextField)`
    width: 100%;
    font: ${font.Forntfamily};
    font-size: 16px;
    & label.Mui-focused {
      color: ${color.Green};
    }
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${color.Gray};
      }
      &:hover fieldset {
        border-color: ${color.Green};
      }
      &.Mui-focused fieldset {
        border-color: ${color.Green};
      }
    }
  `,
  InputAdornment: styled(InputAdornment)``,
  ModalSendButton: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0;
    color: #ffffff;
    background: ${color.MainGreen};
    &:hover {
      background-color: ${color.Green};
    }
    @media ${device.XS} {
      margin: 24px auto 8px;
    }
  `,
};
