import styled from "styled-components";

export const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    text-align: center;
  `,
  Box: styled.div`
    align-items: center;
    margin: 23px auto 0px;
    > * + * {
      margin-top: 16px;
    }
  `,
  Block: styled.div``,
  CheckBoxWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
