import React from "react";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import Looks4OutlinedIcon from "@mui/icons-material/Looks4Outlined";
import Looks5OutlinedIcon from "@mui/icons-material/Looks5Outlined";
import Looks6OutlinedIcon from "@mui/icons-material/Looks6Outlined";

export const ITEMS = [
  {
    id: 0,
    name: "數量",
    icon: <LooksOneOutlinedIcon />,
    question: "q1",
  },
  {
    id: 1,
    name: "類型",
    icon: <LooksTwoOutlinedIcon />,
    question: "q2",
  },
  {
    id: 2,
    name: "款式",
    icon: <Looks3OutlinedIcon />,
    question: "q3",
  },
  {
    id: 3,
    name: "工法",
    icon: <Looks4OutlinedIcon />,
    question: "q4",
  },
  {
    id: 4,
    name: "圖案",
    icon: <Looks5OutlinedIcon />,
    question: "q5",
  },
  {
    id: 5,
    name: "加值服務",
    icon: <Looks6OutlinedIcon />,
    question: "q6",
  },
];
