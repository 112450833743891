import React from "react";

import { FormContext } from "../../../../context/FormContext";

import { S } from "./styles";
import { S as GS } from "../styles";
import { Mui as GMui } from "../stylesMUI";

const Question1 = () => {
  const { form, setForm } = React.useContext(FormContext);
  const value = form.q1.value;

  const handleChange = (e) => {
    const v = parseInt(e.target.value, 10);

    setForm((prev) => ({
      ...prev,
      q1: { status: true, value: v, zhValue: `${v}件` },
    }));
  };

  return (
    <GS.Wrapper>
      <S.Box>
        <GS.Text>輸入預計要訂製的衣服數量</GS.Text>
        <GMui.StyledTextField
          label="輸入數量"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          value={value}
          onChange={handleChange}
        />
      </S.Box>
    </GS.Wrapper>
  );
};

export default Question1;
