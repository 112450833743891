import React from "react";

import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import lineIcon from "../../../../assets/images/lineIcon.png";

import { S } from "./styles";
import { Mui } from "./stylesMui";

const SuccessPage = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.LeftContainer>
          <S.Title>成功送出報價單</S.Title>
          <S.Text>報價單已寄送至您填寫的聯絡信箱，我們將會在24小時內與您聯絡</S.Text>
          <S.Text>也歡迎加入LINE@好友私訊即時詢問！</S.Text>
          <Mui.ModalSendButton
            href="https://line.me/R/ti/p/@llanodesign"
            variant="outlined"
            size="large"
          >
            <S.Img src={lineIcon} />
            <S.LinkText>LINE</S.LinkText>
          </Mui.ModalSendButton>
        </S.LeftContainer>
        <S.RightContainer>
            <S.LinkA href="https://customizehome.llano-design.com/">
              <S.LinkContainer>
                <KeyboardReturnRoundedIcon sx={{ fontSize: 20, color:'#555555' }} />
                <S.LinkText>回到草原客製</S.LinkText>
              </S.LinkContainer>
            </S.LinkA>
            <S.LinkA href="https://llano-design.com/">
              <S.LinkContainer>
                <S.LinkText>前往草原</S.LinkText>
                <HomeRoundedIcon sx={{ fontSize: 20, color:'#555555' }} />
              </S.LinkContainer>
            </S.LinkA>
            <S.LinkA href="https://llano.sazenka-tech.com/?page_id=203">
              <S.LinkContainer>
                <S.LinkText>給我們回饋</S.LinkText>
                <ChatBubbleOutlineRoundedIcon sx={{ fontSize: 20, color:'#555555' }} />
              </S.LinkContainer>
            </S.LinkA>
        </S.RightContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default SuccessPage;
