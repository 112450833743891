import React from "react";

import SubmitPage from "./SubmitPage";
import SuccessPage from "./SuccessPage";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Modal from "../../../components/Modal";

import { S, modalCSS } from "./styles";

export const WrapperModal = ({ visible, setVisible }) => {
  const [submited, setSubmited] = React.useState(false);

  const renderCloseBtn = () => {
    return (
      <S.CancelBtn onClick={() => setVisible(false)}>
        <CloseRoundedIcon sx={{ fontSize: 32 }} />
      </S.CancelBtn>
    );
  }
  return (
    <Modal visible={visible} modalCSS={modalCSS}>
      {submited ? null : renderCloseBtn()}
      {submited ? <SuccessPage /> : <SubmitPage setSubmited={setSubmited} />}
    </Modal>
  );
};

export const ImageModal = (props) => {
  const renderCloseBtn = () => {
    return (
      <S.CancelBtn onClick={() => props.setModel({visible: false,  imgLink: ""})}>
        <CloseRoundedIcon sx={{ fontSize: 32 }} />
      </S.CancelBtn>
    );
  }
  return (
    <Modal visible={props.visible} modalCSS={modalCSS}>
      {renderCloseBtn()}
      <S.ImageWrapper>
        <S.Image 
        src={props.imgLink} 
        srcSet={props.imgLink} 
        alt="圖片載入中"
         />
      </S.ImageWrapper>
    </Modal>
  );
};
