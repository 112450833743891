
export const size = {
  S: '576px',
  M: '768px',
  L: '992px',
  XL: '1200px',
};

export const device = {
  XS: `(max-width: ${size.S})`,
  S: `(min-width: ${size.S})`,
  XM: `(max-width: ${size.M})`,
  M: `(min-width: ${size.M})`,
  L: `(min-width: ${size.L})`,
  XL: `(min-width: ${size.XL})`,
};

export const color ={
  MainGreen: `#5B6F4E`,
  Green:`#799C54`,
  SubMainGreen:`#55704a`,
  Brown:`#993300`,
  Orange: `#F1A53D`,
  Black: `#222222`,
  Gray: `#888888`,
  ButtonGray:`#ebebeb`,
  Background:`#F0F0F0`,
  TextTitle:`#212529`,
  Text:`#555555`,
  White: `#ffffff`
};


export const font = {
  Forntfamily: `GenJyuuGothicL, font-family: open sans,微軟正黑體,Arial,Helvetica,sans-serif,stheiti light,儷黑 pro,lihei pro,microsoft yahei,microsoft jhenghei,新細明體!important;`
};