import styled from "styled-components";
import { color, font, device } from "../../../../core/unit/styles";

export const S = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 22px;
    @media ${device.XS} {
      width: 100%;
    }
  `,
  Container: styled.div`
    width: 60%;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 26px 30px 55px;
    @media ${device.XS} {
      width: 80%;
    }
  `,
  LeftContainer: styled.div`
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    @media ${device.XS} {
      display: block;
    }
  `,
  RightContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    @media ${device.XS} {
      display: block;
    }
  `,
  Title: styled.p`
      font-size: 20px;
      font-family: ${font.Forntfamily}
      color: ${color.Text};
      text-align: center;
      margin-bottom: 24px;
      letter-spacing: 2px;
    `,
  Text: styled.p`
    font: ${font.Forntfamily};
    letter-spacing: 1px;
    text-align:center;
    color: ${color.Text};
    margin-top: 12px;
  `,
  Info: styled.h3`
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-family: ${font.FontFamily};
    letter-spacing: 0.1px;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-top: 4px;
    color: ${color.Gray};
  `,
  Img: styled.img`
    width: auto;
    height: auto;
  `,
  LinkText: styled.p`
    font-size: 16px;
    align-items: center;
    text-align: center;
    font: ${font.Forntfamily};
    letter-spacing: 2px;
    color: ${color.Text};
    transition: color 0.5s;
    &:hover {
      color: ${color.Green};
    }
  `,
  LinkContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    margin: 12px;
  `,
  LinkA: styled.a`
    transition: all .3s ease 0s;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  `,
};
