import React from "react";

import Sidebar from "./Sidebar";
import Question1 from "./Question/Question1";
import Question2 from "./Question/Question2";
import Question3 from "./Question/Question3";
import Question4 from "./Question/Question4";
import Question5 from "./Question/Question5";
import Question6 from "./Question/Question6";
import {WrapperModal} from "./WrapperModal";

import Logo from "../../assets/images/logo.webp";

import { S } from "./styles";
import { ITEMS } from "./constant";

const Form = () => {
  const [selected, setSelected] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const renderQuestion = () => {
    switch (selected) {
      case 0:
        return <Question1 />;
      case 1:
        return <Question2 />;
      case 2:
        return <Question3 />;
      case 3:
        return <Question4 />;
      case 4:
        return <Question5 />;
      case 5:
        return <Question6 />;
      default:
        return <Question1 />;
    }
  };

  return (
    <S.Wrapper>
      <S.Left>
        <S.LeftWrapper>
          <S.LeftTitleLogo>
            <a href="https://llano-design.com/">
              <S.Img src={Logo} />
            </a>
          </S.LeftTitleLogo>
          <S.LeftHeader>
            <S.LeftHeaderTitle>{ITEMS[selected].name}</S.LeftHeaderTitle>
          </S.LeftHeader>
          <S.LeftContainer>{renderQuestion()}</S.LeftContainer>
        </S.LeftWrapper>
      </S.Left>
      <S.Right>
        <Sidebar
          selected={selected}
          setSelected={setSelected}
          setVisible={setVisible}
        />
      </S.Right>
      <WrapperModal visible={visible} setVisible={setVisible} />
    </S.Wrapper>
  );
};

export default Form;
