import styled from 'styled-components';

export const S = {
  Wrapper: styled.div`
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    overflow: scroll;
  `,
  ModalGrey: styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  `,
  Container: styled.div`
    max-width: 100%;
    max-height: 100%;
    z-index: 2001;
    margin: 0 auto;
    display: table;
    position: relative;
    background: white;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-animation: FadeIn 350ms linear;
    -moz-animation: FadeIn 350ms linear;
    animation: FadeIn 350ms linear;
    -webkit-animation-name: FadeIn;
    -moz-animation-name: FadeIn;
    animation-name: FadeIn;
    ${(props) => props.modalCSS}
    @-webkit-keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-moz-keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-o-keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes FadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  Cross: styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    width: 20px;
    height: 20px;

    &:before {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 18px;
      width: 2px;
      background-color: #333;
      transform: rotate(45deg);
    }
    &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 18px;
      width: 2px;
      background-color: #333;
      transform: rotate(-45deg);
    }
  `,
  Title: styled.h1`
    margin: 24px 0 0;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 154.3%;
    text-align: center;
    color: #171822;
  `,
};
