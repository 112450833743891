import React from "react";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { lightGreen, grey } from "@mui/material/colors";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { FormContext } from "../../../context/FormContext";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import { calculatePrice } from "../../../core/utils/price";
import useOnClickOutside from "../../../core/hook/ousideClick";

import { S } from "./styles";
import { Mui } from "./stylesMui";
import { ITEMS } from "../constant";
import DATA from "../../../assets/data/productsList.json";

const Sidebar = ({ selected, setSelected, setVisible }) => {
  const ref = React.useRef(null);
  const { form } = React.useContext(FormContext);
  const [isClick, setIsClick] = React.useState(false);

  const status =
    form.q1.status &&
    form.q2.status &&
    form.q3.status &&
    form.q4.status &&
    form.q5.status;

  useOnClickOutside(ref, () => {
    setIsClick(false);
  });

  let price = calculatePrice(form, DATA);

  const renderMobileHeader = () => {
    return (
      <S.MobileHeader
        onClick={() => {
          setIsClick(true);
        }}
      >
        <Mui.ListItemButton style={{ display: isClick ? "none" : "flex" }}>
          <Mui.ListItemIconForMobile><ArrowDropDownRoundedIcon /></Mui.ListItemIconForMobile>
          <Mui.ListItemIcon>{ITEMS[selected]["icon"]}</Mui.ListItemIcon>
          <Mui.ListItemText primary={ITEMS[selected]["name"]} />
          <Mui.ListItemAnswer primary={ITEMS[selected]["zhValue"]} />
          {form[ITEMS[selected]["question"]]["status"] ? (
            <CheckCircleOutlineRoundedIcon sx={{ color: lightGreen[800] }} />
          ) : (
            <ErrorOutlineRoundedIcon sx={{ color: grey[300] }} />
          )}
        </Mui.ListItemButton>
      </S.MobileHeader>
    );
  };
  const renderBtn = () => {
    return (
      <S.ButtonWrapper>
        {selected === 0 ? null : (
          <Mui.Button
            onClick={() => {
              if (selected > 0) {
                setSelected(selected - 1);
              }
            }}
            disabled={selected === 3 && form.q2.status === false ? true : false}
          >
            上一步
          </Mui.Button>
        )}
        {selected === ITEMS.length - 1 ? (
          status === true ? (
            <Mui.SubmitButton
            onClick={() => {
              setVisible(true);
            }}
          >
            送出表單
            <NavigateNextRoundedIcon />
          </Mui.SubmitButton>
          ) : (
            <Mui.SubmitButton>
            尚有題目未填寫喔
            <NavigateNextRoundedIcon />
          </Mui.SubmitButton>
          )
        ) : (
          <Mui.NextButton
            onClick={() => {
              if (selected < ITEMS.length - 1) {
                setSelected(selected + 1);
              }
            }}
            disabled={selected === 1 && form.q2.status === false ? true : false}
          >
            下一步
          </Mui.NextButton>
        )}
      </S.ButtonWrapper>
    );
  };

  return (
    <S.Wrapper>
      <S.Container ref={ref}>
        {renderMobileHeader()}
        <Mui.List
          click={isClick}
          component="nav"
          aria-label="main mailbox folders"
        >
          {ITEMS.map((v, i) => {
            const questionStatus = form[v["question"]]["status"];
            const zhValue = form[v["question"]]["zhValue"];
            const disabled = form.q2.status === false && v.id === 2;

            return (
              <Mui.ListItemButton
                key={i}
                selected={selected === v.id}
                onClick={() => {
                  setSelected(v.id);
                  setIsClick(false);
                }}
                disabled={disabled}
              >
                <Mui.ListItemIconForMobile><ArrowDropUpRoundedIcon /></Mui.ListItemIconForMobile>
                <Mui.ListItemIcon>{v.icon}</Mui.ListItemIcon>
                <Mui.ListItemText primary={v.name} />
                <Mui.ListItemAnswer primary={zhValue} />
                {questionStatus ? (
                  <CheckCircleOutlineRoundedIcon
                    sx={{ color: lightGreen[800] }}
                  />
                ) : (
                  <ErrorOutlineRoundedIcon sx={{ color: grey[300] }} />
                )}
              </Mui.ListItemButton>
            );
          })}
        </Mui.List>
        <Mui.Divider />
      </S.Container>

      <S.Footer>
        <S.PriceBox>
          <S.TextBox>
            <S.Text>預估金額：{price} 起 </S.Text>
            <S.SubText>／件（未稅）</S.SubText>
          </S.TextBox>
          
        </S.PriceBox>
        {renderBtn()}
      </S.Footer>
    </S.Wrapper>
  );
};

export default Sidebar;
