import React, { Component } from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";

import { S } from "./styles";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

class Modal extends Component {
  render() {
    const portalTarget = document.body;
    return (
      <>
        {ReactDOM.createPortal(
          <S.Wrapper visible={this.props.visible}>
            {this.props.visible ? <GlobalStyle /> : null}

            <S.ModalGrey
              onClick={() => {
                this.props.onClose();
              }}
            />
            <S.Container modalCSS={this.props.modalCSS}>
              {this.props.children}
            </S.Container>
          </S.Wrapper>,
          portalTarget
        )}
      </>
    );
  }
}

export default Modal;
