import styled, { css } from "styled-components";
import { device } from "../../../core/unit/styles";

export const S = {
  CancelBtn: styled.div`
    position: absolute;
    z-index: 1000;
    top: 32px;
    right: 31px;
    cursor: pointer;
    @media ${device.XS} {
      top: 20px;
      right: 28px;
    }
  `,
  ImageWrapper: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Image: styled.img`
    cursor: pointer;
    padding: 4px;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    @media ${device.XS} {
      height: 80%;
      width: 80%;
    }
  `,
};

export const modalCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
