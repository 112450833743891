import { styled } from "@mui/system";
import { TextField, Typography } from "@mui/material";
import { color, font, device } from "../../../core/unit/styles";

export const Mui = {
  StyledTextField: styled(TextField)`
    font-size: 16px;
    font-family: ${font.Forntfamily};
    outline: true;
    width: 90%;
    margin: 4px;
    text-align: center;
    & label.Mui-focused {
      color: ${color.Green};
    }
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${color.Black};
      }
      &:hover fieldset {
        border-color: ${color.Green};
      }
      &.Mui-focused fieldset {
        border-color: ${color.Green};
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    @media ${device.XS} {
      width: 60%;
    }
  `,
  Typography: styled(Typography)`
    font-size: 12px;
    margin-top: 6px
    letter-spacing: 1px;
    font-weight:400;
    width:100%;
  `,
  TitleTypography: styled(Typography)`
    font-size: 16px;
    margin-top: 4px
    letter-spacing: 2px;
    font-weight:700;
  `,
};
