import React from "react";

import { FormContext } from "../../../../context/FormContext";
import { S } from "./styles";
import { Mui } from "./stylesMUI";
import { S as GS } from "../styles";

const Question6 = () => {
  const { form, setForm } = React.useContext(FormContext);
  const value = form.q6.value;

  React.useEffect(() => {
    setForm((prev) => ({
      ...prev,
      q6: {
        ...prev.q6,
        status: true,
        zhValue: prev.q6.value ? "需要加值服務" : "無需加值服務",
      },
    }));
  }, [setForm]);

  const handleChange = (e) => {
    const v = e.target.checked;
    setForm((prev) => ({
      ...prev,
      q6: { status: true, value: v, zhValue: v ? "需要加值服務" : "無需加值服務" },
    }));
  };

  const renderCheckBox = () => {
    return (
      <S.Block>
        <S.CheckBoxWrapper>
          <Mui.StyledFormControlLabel
            control={
              <Mui.StyledCheckbox checked={value} onChange={handleChange} />
            }
            label="需要加值服務"
          />
        </S.CheckBoxWrapper>
        <GS.Info>勾選這個選項，我們將在後續與您討論！</GS.Info>
      </S.Block>
    );
  };

  return (
    <S.Wrapper>
      <GS.Text>另提供以下服務，歡迎加入LINE@好友私訊詢問！</GS.Text>
      <GS.Text>・急件</GS.Text>
      <GS.Text>・套量</GS.Text>
      <GS.Text>・打樣</GS.Text>
      <GS.Text>・手繪轉電子檔</GS.Text>
      <GS.Text>・委託設計</GS.Text>
      <GS.Text>・提供統計表單</GS.Text>
      <GS.Text>・協助攝影</GS.Text>
      <GS.Text>・客製化包裝、吊牌、品牌標</GS.Text>
      <GS.Text>・代出貨</GS.Text>
      <GS.Text>⋯⋯</GS.Text>
      <S.Box>{renderCheckBox()}</S.Box>
    </S.Wrapper>
  );
};

export default Question6;
