import { styled } from "@mui/system";
import {
  TextField,
  InputAdornment,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  ImageListItem,
  ImageListItemBar
} from "@mui/material";
import { color, font, device } from "../../../../core/unit/styles";

export const Mui = {
  StyledTextField: styled(TextField)`
    font-family: ${font.Forntfamily};
    color: ${color.Gray};
    width: 20%;
    text-align: center;
    margin: 0px 5px 10px 5px;
    & label.Mui-focused {
      color: ${color.Green};
    }
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${color.Black};
      }
      &:hover fieldset {
        border-color: ${color.Green};
      }
      &.Mui-focused fieldset {
        border-color: ${color.Green};
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  StyledInputAdornment: styled(InputAdornment)``,
  StyledFormControl: styled(FormControl)`
    margin: 1px 5px;
    padding-left: 40px;
    height: 100%;
    color: ${color.Black};
    font-family: ${font.Forntfamily};
    & label.Mui-focused {
      color: ${color.Green};
    }
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: ${color.Black};
      }
      &:hover fieldset {
        border-color: ${color.Gray};
      }
      &.Mui-focused fieldset {
        border-color: ${color.Green};
      }
    }
  `,

  StyledRadioGroup: styled(RadioGroup)``,
  StyledFormControlLabel: styled(FormControlLabel)``,
  StyledRadio: styled(Radio)`
    color: ${color.Black};
    &.Mui-checked {
      color: ${color.Green}
    }
  `,
  ImageListItem: styled(ImageListItem)`
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    box-shadow: unset;
    transition: 0.25s;
    align-items: center;
    justify-content: center;
  `,
  ImageListItemBar: styled(ImageListItemBar)`
    display: flex;
    width: 100%;
    @media ${device.XS} {
      height: 80%;
    }
  `,
};
