import styled from "styled-components";
import { font, color, device } from "../../../core/unit/styles";

export const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-left: 1px solid #d8d8d8;
    font-size: 14px;
    line-height: 20px;
  `,

  Container: styled.div`
    width: 100%;
    background: white;
  `,
  MobileHeader: styled.div`
    display: none;
    @media ${device.XS} {
      display: block;
    }
  `,
  Footer: styled.div`
    width: 100%;
    color: #fff;
    background: #2d2d2d;
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    height: 60px;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    display: flex;
  `,
  DescriptionBox: styled.div`
    width: 100%;
    max-width: 780px;
    height: calc(100% - 340px);
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
  `,
  PriceBox: styled.div`
    display: flex;
    width: 100%;
    max-width: 780px;
    height: 60px;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    background: ${color.Background};
  `,
  ImgBox: styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
  `,
  TextBox: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  `,
  Text: styled.p`
    font-size: 16px ;
    font-family: ${font.Forntfamily}
    letter-spacing: 0.2px;
    color: ${color.MainGreen};
  `,
  SubText: styled.p`
    font-size: 12px;
    font-family: ${font.FontFamily};
    letter-spacing: 0.2px;
    color: ${color.Gray};
  `,
};
