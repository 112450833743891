import { styled } from "@mui/system";
import { font, color, device } from "../../../core/unit/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";

export const Mui = {
  List: styled(List)`
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2000;
    background: white;
    
    @media ${device.XS} {
      display: ${(props) => (props.click ? "block" : "none")};
    }
  `,
  ListItemButton: styled(ListItemButton)`
    justify-content: center;
      align-items: center;
  `,
  ListItemIconForMobile: styled(ListItemIcon)`
    display: none;
    @media ${device.XS} {
      justify-content: center;
      align-items: center;
      min-width: 30px;
      display: block;
    }
  `,
  ListItemIcon: styled(ListItemIcon)``,
  ListItemText: styled(ListItemText)`
    max-width: 30%;
  `,
  ListItemAnswer: styled(ListItemText)`
    font-size: 16px/19px ;
    font-family: ${font.Forntfamily}
    font-weight: 400;
    letter-spacing: 0.2px;
    color: ${color.Text};
  `,
  Divider: styled(Divider)``,
  Button: styled(Button)`
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 1 50%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: ${color.Black};
    background: ${color.ButtonGray};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    border: none;
    border-radius: 0;
    position: relative;
    display: flex;
    &:hover {
      background-color: ${color.Gray};
    }
  `,
  NextButton: styled(Button)`
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 1 50%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    background: ${color.MainGreen};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    border: none;
    border-radius: 0;
    position: relative;
    display: flex;
    &:hover {
      background-color: ${color.Green};
    }
  `,
  SubmitButton: styled(Button)`
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 1 50%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #ffffff;
    background: ${color.Black};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    border: none;
    border-radius: 0;
    position: relative;
    display: flex;
    &:hover {
      background-color: ${color.Gray};
    }
  `,
};
