import { styled } from "@mui/system";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { device } from "../../../../core/unit/styles";

export const Mui = {
  ImageList: styled(ImageList)`
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 450px;

    @media ${device.M} {
      width: 500px;
      height: 450px;
    }
    @media ${device.S} {
      width: 350px;
      height: 350px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
        background: rgba(143, 143, 143, 0.5);
      }
      &::-webkit-scrollbar-thumb {
        padding: 2px;
        border-radius: 4px;
        background: rgba(224, 224, 224, 1);
      }
    }
    @media ${device.XS} {
      width: 300px;
      height: 300px;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
        background: rgba(143, 143, 143, 0.5);
      }
      &::-webkit-scrollbar-thumb {
        padding: 2px;
        border-radius: 4px;
        background: rgba(224, 224, 224, 1);
      }
    }
  `,
  ImageListItem: styled(ImageListItem)`
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    box-shadow: unset;
    transition: 0.25s;
    align-items: center;
    justify-content: center;
  `,
  ImageListItemBar: styled(ImageListItemBar)`
    display: flex;
    width: 100%;
    @media ${device.XS} {
      height: 80%;
    }
  `,
};
