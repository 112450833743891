import React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { FormContext } from "../../../../context/FormContext";

import { S } from "./styles";
import { Mui } from "./stylesMUI";
import { S as GS } from "../styles";
import { Mui as GMui } from "../stylesMUI";

import { ImageModal } from "../../WrapperModal";

import DATA from "../../../../assets/data/productsList.json";

const Question2 = () => {
  const { form, setForm } = React.useContext(FormContext);
  const [data] = React.useState(DATA);
  const value = form.q2.value;
  const [model, setModel] = React.useState({
    visible: false,
    imgLink: "",
  });

  const onClick = (v, n) => {
    setForm((prev) => ({
      ...prev,
      q2: { status: true, value: v, zhValue: n },
      q3: { status: false, value: "", zhValue: "" },
    }));
  };

  const onVisibleModel = (imgLink) => {
    setModel((props) => ({ ...props, visible: true,  imgLink: imgLink}));
  }

  const renderContainer = () => {
    return (
      <S.ListContainer>
        <ImageList
          gap={12}
          sx={{
            m: 2,
            gridTemplateColumns:
              "repeat(auto-fill,minmax(280px, 1fr)) !important",
          }}
        >
          {Object.keys(data).map((v, i) => (
            <Mui.ImageListItem key={i}>
              <img
                name={data[v]["name_zh"]}
                src={`/images/${data[v].img}`}
                srcSet={`/images/${data[v].img}`}
                alt={v}
                loading="lazy"
                style={{
                  cursor: "pointer",
                  padding: "4px",
                  height: "100%",
                  width: "100%",
                }}
                onClick={() => onClick(v, data[v]["name_zh"])}
              />
              <ImageListItemBar
                title={<GMui.Typography>{data[v]["description"]}</GMui.Typography>}
                subtitle={
                  <GS.TypographyBox>
                    <GMui.TitleTypography>{data[v]["name_zh"]}</GMui.TitleTypography>
                    <GMui.Typography>{data[v]["description_price"]} 元起</GMui.Typography>
                  </GS.TypographyBox>
                }
                style={{ zIndex: 2000, backgroundColor: 'rgba(10, 10, 10, 0.4)' }}
                actionIcon={
                  <IconButton
                    size="large"
                    title="商品資訊"
                    sx={{ color: "rgba(255, 255, 255, 0.8)", zIndex: 2000 }}
                    aria-label={`info about ${data[v]["name_zh"]}`}
                    onClick={() => onVisibleModel(`/images/${data[v].img}`)}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              >

              </ImageListItemBar>
              {value === v ? (
                <S.Overlay>
                  <CheckCircleOutlineRoundedIcon
                    sx={{ fontSize: 70, color: "white", zIndex: "1000" }}
                  />
                  <S.GreyOverlay />
                </S.Overlay>
              ) : null}
            </Mui.ImageListItem>
          ))
          }
        </ImageList >
      </S.ListContainer >
    );
  };
  return (
    <GS.Wrapper>
      <GS.Box>
        <GS.Text>選擇你想製作的產品類型</GS.Text>
        {renderContainer()}
      </GS.Box>
      <ImageModal visible={model.visible} setModel={setModel} imgLink={model.imgLink}></ImageModal>
    </GS.Wrapper>
  );
};

export default Question2;
