import React from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import { FormContext } from "../../../../context/FormContext";

import { S } from "./styles";
import { Mui } from "./stylesMUI";
import { S as GS } from "../styles";
import { Mui as GMui } from "../stylesMUI";

import { ITEMS } from "./constant";

const Question4 = () => {
  const { form, setForm } = React.useContext(FormContext);
  const value = form.q4.value;
      
  const onClick = (v) => {
    setForm((prev) => ({
      ...prev,
      q4: {
        ...prev.q4,
        status: true,
        value: v.value,
        zhValue: v.label,
      },
    }));
  };

  const renderContainer = () => {
    return (
      <S.ListContainer>
        <ImageList
          gap={12}
          sx={{
            m: 2,
            gridTemplateColumns:
              "repeat(auto-fill,minmax(280px, 1fr)) !important",
          }}
        >
          {ITEMS.map((v) => (
            <Mui.ImageListItem>
              <img
                name={v.label}
                src={v.descriptionImg}
                srcSet={v.descriptionImg}
                alt={v}
                loading="lazy"
                style={{
                  cursor: "pointer",
                  padding: "4px",
                  height: "98%",
                  width: "98%",
                }}
                onClick={() => onClick(v)}
              />
              <ImageListItemBar
                title={<GMui.TitleTypography>{v.label}</GMui.TitleTypography>}
                subtitle={<GMui.Typography>{v.info}</GMui.Typography>}
                style={{ zIndex: 2000, backgroundColor: 'rgba(10, 10, 10, 0.4)'}}
               >
              </ImageListItemBar>
              {value === v.value ? (
                <S.Overlay>
                  <CheckCircleOutlineRoundedIcon
                    sx={{ fontSize: 70, color: "white", zIndex: "1000" }}
                  />
                  <S.GreyOverlay />
                </S.Overlay>
              ) : null}
            </Mui.ImageListItem>
          ))}
        </ImageList>
      </S.ListContainer>
    );
  };

  return (
    <GS.Wrapper>
      <GS.Text>網版印刷以外的工法，需來訊報價，我們將在後續和您討論細節。</GS.Text>
      <GS.Box>
        {renderContainer()}
      </GS.Box>
    </GS.Wrapper>
  );
};

export default Question4;
