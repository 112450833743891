import React, { Component } from "react";

const FormContext = React.createContext();

const FormProvider = (props) => {
  const [form, setForm] = React.useState({
    q1: {
      status: false,
      value: "",
      zhValue: "",
    },
    q2: {
      status: false,
      value: "",
      zhValue: "",
    },
    q3: {
      status: false,
      value: "",
      zhValue: "",
    },
    q4: {
      status: false,
      value: "",
      zhValue: "",
    },
    q5: {
      status: false,
      p1: "",
      p2: "",
      isWithoutImage:"",
      zhValue: "",
    },
    q6: {
      status: false,
      value: true,
      zhValue: "",
    },
  });

  return (
    <FormContext.Provider
      value={{
        form: form,
        setForm: setForm,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export const withFormConsumer = (WrappedComponent) => {
  return class extends Component {
    render() {
      return (
        <FormContext.FormConsumer>
          {(value) => <WrappedComponent {...this.props} context={value} />}
        </FormContext.FormConsumer>
      );
    }
  };
};

export { FormProvider, FormContext };
