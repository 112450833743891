import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { color, device} from "../../../../core/unit/styles";

export const Mui = {
  ModalSendButton: styled(Button)`
    width: 40%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 40px auto 12px;
    color: ${color.Black};
    border-color: ${color.Gray} !important;
    &:hover {
      background: rgba(171, 171, 171, 0.1);
      border-color: ${color.Green} !important;
    }
    @media ${device.XS} {
      width: 60%;
    }
  `,
};
