import styled from "styled-components";

import { device, font, color } from "../../../core/unit/styles";

export const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    @media ${device.XS} {
      width: 90%;
      padding: 8px;
    }
    @media ${device.S} {
    }
  `,
  Text: styled.p`
    width:100%
    font-size: 16px/19px ;
    font-family: ${font.Forntfamily}
    font-weight: 400;
    letter-spacing: 0.2px;
    color: ${color.Text};
    margin: 0px 0px 12px 0px;
    text-align: center;
    @media ${device.XS} {
      margin: 0px 0px 8px 0px;
    }
  `,
  Title: styled.p`
    font-size: 16px;
    font-family: ${font.FontFamily};
    letter-spacing: 0.26px;
    color: ${color.Black};
    margin-bottom: 1%;
  `,
  Info: styled.h3`
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-family: ${font.FontFamily};
    letter-spacing: 0.1px;
    padding-top: 2px;
    padding-bottom: 5px;
    margin-top: 4px;
    color: ${color.Gray};
  `,
  Box: styled.div`
    height: 100%;
    display: block;
    align-items: center;
    text-align: center;
    vertical-align: middle;
  `,
  TypographyBox: styled.div`
    justify-content: center:
    align-items: center;
    text-aligns: center;
  `,
};
