import React from "react";

import { FormContext } from "../../../../context/FormContext";
import { InputLabel } from "@mui/material";

import { S } from "./styles";
import { Mui } from "./stylesMUI";
import { S as GS } from "../styles";

const Question5 = () => {
  const { form, setForm } = React.useContext(FormContext);
  const [checked, setChecked] = React.useState(false);

  // handle select change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => {
      let status = false;
      let zhValueArr =
        prev.q5.zhValue.length === 0 ? ["", ""] : prev.q5.zhValue.split(",");

      if (name === "p1") {
        status = prev.q5.p2 === "" ? false : true;
        zhValueArr[0] = value + "個圖案";
      } else {
        status = prev.q5.p1 === "" ? false : true;
        zhValueArr[1] = value + "個顏色";
      }

      return {
        ...prev,
        q5: {
          ...prev.q5,
          [name]: value,
          status: status,
          isWithoutImage: "",
          zhValue: zhValueArr.join(","),
        },
      };
    });
  };

  const renderPicBox = () => {
    const p1 = form.q5.p1;
    const picList = [];
    for (let i = 0; i <= 4; i += 1) {
      picList.push(i);
    }
    return (
      <S.Block>
        <S.Item>
          <S.Text>網版印刷共有幾個圖案：</S.Text>
          <Mui.StyledFormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel sx={{ bgcolor: "#ffffff !important" }}>
              選擇數量
            </InputLabel>
            <Mui.StyledSelect
              name="p1"
              value={p1}
              onChange={handleChange}
              disabled={checked}
            >
              {picList.map((v) => {
                return (
                  <Mui.StyledMenuItem key={v} value={v}>
                    {v}
                  </Mui.StyledMenuItem>
                );
              })}
            </Mui.StyledSelect>
          </Mui.StyledFormControl>
        </S.Item>
        <GS.Info>「 註：A4 範圍內為一個圖案 」</GS.Info>
      </S.Block>
    );
  };

  const renderColorBox = () => {
    const p2 = form.q5.p2;
    const colorList = [];
    for (let i = 0; i <= 8; i += 1) {
      colorList.push(i);
    }
    return (
      <S.Block>
        <S.Item>
          <S.Text>網版印刷共有幾個顏色：</S.Text>
          <Mui.StyledFormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel sx={{ bgcolor: "#ffffff !important" }}>
              選擇數量
            </InputLabel>
            <Mui.StyledSelect
              name="p2"
              value={p2}
              onChange={handleChange}
              disabled={checked}
            >
              {colorList.map((v) => {
                return (
                  <Mui.StyledMenuItem key={v} value={v}>
                    {v}
                  </Mui.StyledMenuItem>
                );
              })}
            </Mui.StyledSelect>
          </Mui.StyledFormControl>
        </S.Item>
        <GS.Info>「 註：需重複計算，例如：兩個圖案都有白色，算兩色 」</GS.Info>
      </S.Block>
    );
  };

  const renderCheckBox = () => {
    return (
      <S.Block>
        <S.CheckBoxWrapper>
          <Mui.StyledFormControlLabel
            control={
              <Mui.StyledCheckbox
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  if (e.target.checked) {
                    setForm((prev) => {
                      return {
                        ...prev,
                        q5: {
                          ...prev.q5,
                          p1: 0,
                          p2: 0,
                          isWithoutImage: "無圖案報價",
                          status: true,
                          zhValue: "0個圖案,0個顏色",
                        },
                      };
                    });
                  }
                }}
              />
            }
            label="其他工法／尚未有想法"
          />
        </S.CheckBoxWrapper>
        <GS.Info>
          勾選這個選項將以無圖案報價，我們將在後續和您討論圖片顏色與設計。
        </GS.Info>
      </S.Block>
    );
  };

  return (
    <GS.Wrapper>
      <GS.Text>網版印刷以外的工法，請選擇「其他工法／尚未有想法」。</GS.Text>
      <S.Box>
        {renderCheckBox()} {renderPicBox()} {renderColorBox()} 
      </S.Box>
    </GS.Wrapper>
  );
};

export default Question5;
