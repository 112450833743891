import styled from "styled-components";
import { device } from "../../core/unit/styles";

export const S = {
  Wrapper: styled.div`
    
    width: 100%;
    height: 100%;
    line-height: 1;
    font-size: 14px;
    display: flex;
    position: fixed;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin: 0 auto;
  `,
  Left: styled.div`
    width: 100%;
    height: 100%;
    -webkit-box-flex: 1;
    flex: 1 2 400px;
    position: relative;
    overflow: hidden;
    display: blcok;

    @media ${device.XS} {
      position: absolute;
      z-index: 1000;
      overflow-x: hidden;
      overflow-y: scroll;
      bottom: 140px;
      height: calc(100% - 200px);
    }
  `,
  Right: styled.div`
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 335px;
    max-width: 600px;
    position: relative;
  `,
  LeftWrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
    max-width: 780px;
    margin: 0 auto;
  `,
  LeftHeader: styled.div`
    margin: 0px 0 20px;
    @media ${device.XS} {
      margin: 0px 0 12px;
    }
  `,
  LeftTitleLogo: styled.div`
    width: 10%;
    height: 10%;
    display: flex;
    cursor: pointer;
    @media ${device.XS} {
      display: none;
    }
  `,
  LeftHeaderTitle: styled.h1`
    color: #2d2d2d;
    font-size: 24px;
    text-align: center;
    margin: 0 auto 16px;
    @media ${device.XS} {
      margin: 16px auto 8px;
    }
  `,
  LeftContainer: styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
  LogoContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 30px;
    margin: 50px;
    cursor: pointer;
  `,
  Img: styled.img`
    width: auto;
    height: 100%;
  `,
};
